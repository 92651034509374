@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
/* font-family: 'Ubuntu', sans-serif; */
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}


@layer base {
  html {
    font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  }
}
.home {
  font-family: 'Ubuntu';
}
.wrapper {
  display: flex;
}

.wrapper > div:first-child {
  margin-right: 30px;
}
