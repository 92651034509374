* {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

h1 {
  color: black;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 20.5pt;
}

.s1 {
  color: black;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 14pt;
}

h2 {
  color: #00404D;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 17pt;
}

.s2 {
  color: black;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

.s3 {
  color: black;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 12pt;
}

/* List and Table Styles */
table {
  border-collapse: collapse;
  margin-left: 5.366pt;
}

table td {
  padding: 5px;
}

#l1,
#l2,
#l3 {
  list-style-type: none;
  padding-left: 0;
  counter-reset: c1 1;
}

#l1>li>*:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) " ";
}

#l2>li>*:first-child:before {
  counter-increment: c1;
  content: counter(c1, decimal) "." counter(c2, decimal) " ";
}

.whitepaper-container {
  display: flex;
  margin: 0 auto;
}

h3 {
  color: #003366;
  font-family: "San Francisco", -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 15pt;
  margin-top: 30px;
  margin-bottom: 15px;
}

.sidebar {
  position: sticky;
  top: 0px;
  width: 250px;
  max-height: 100vh; 
  overflow-y: auto;  
  background-color: #f5f5f5;
  padding: 20px;
  margin-right: 20px;
  border-right: 2px solid #eaeaea;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #0056b3;
  text-decoration: none;
  display: block;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.collapsible {
  cursor: pointer;
}

.content {
  flex: 1;
  max-width: 700px; /* Adjust the max width as per your preference */
  margin: 0 auto; /* This centers the container */
  padding: 20px; /* Add some padding inside for breathing room */
}

.back-button-container {
  margin: 20px;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.back-button:hover {
  background-color: #0056b3;
}


.section {
  margin-bottom: 40px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .whitepaper-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .sidebar {
    position: relative;
    top: auto;
    width: 100%;
    max-height: none;
    border-right: none;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .content {
    flex: 1;
    display: block;
    padding: 10px;
    margin-right: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
}